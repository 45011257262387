<template>
  <button
    class="sz-w-12 sz-h-12 sz-flex sz-items-center sz-self-end sz-justify-center sz-p-2 sz-space-x-3 sz-text-black sz-transition-all sz-rounded-full sz-outline-none sz-bg-tpfYellow-500 hover:sz-bg-tpfYellow-700 hover:sz-text-white sz-transition-100 sz-transition-ease-in sz-text-1.5xl active:sz-outline-none"
    data-testid="button-onboarding-close"
    @click="toggleChat"
  >
    <SvgIcon name="times-light" class="sz-w-12 sz-h-8" />
  </button>
</template>

<script setup lang="ts">
import SvgIcon from "@/components/SvgIcon.vue";

const emit = defineEmits(["toggleChat"]);

const toggleChat = () => {
  emit("toggleChat");
};
</script>
