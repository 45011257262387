<template>
  <div
    ref="widget"
    class="sz-max-w-sm sz-p-4 sz-rounded-lg sz-min-h-64 popup"
    :class="overflowClass"
  >
    <div class="popup--header">
      <p
        class="sz-mb-3 sz-font-serif sz-text-3xl sz-text-black sz-font-semibold"
      >
        {{ t("tenantMessages.widgetBoxTitle") }}
      </p>
      <p class="sz-mb-2 sz-font-serif sz-text-lg">
        {{ t("tenantMessages.widgetBoxText") }}
      </p>
    </div>
    <OnboardingChatBody :dialogs="[dialog]" class="sz-mt-8" :is-popup="true" />
    <OnboardingActions
      v-if="showAnswers"
      :key="questionStep"
      ref="actions"
      class="sz-mt-8 popup--actions"
      :answers="possibleAnswers"
      @update-step="updateQuestionStep"
    />
    <div v-show="!showAnswers" class="h-16"></div>
  </div>
</template>

<script lang="ts" setup>
import { Genders } from "@/types";

import OnboardingChatBody from "@/components/OnboardingChatBody.vue";
import OnboardingActions from "@/components/OnboardingActions.vue";
import { ref, type Ref, computed, onMounted, nextTick } from "vue";
import { useI18n } from "vue-i18n";
import { useOnboardingStore } from "@/store/Onboarding";
import type IDialog from "@/interfaces/IDialog";
import { useRuntimeEnv } from "@core/src/helpers/environment";

const runtimeEnv = useRuntimeEnv();

const onboardingStore = useOnboardingStore();

const QUESTION_DELAY = 1000;

// have a flip to know that we're currently pushing something to the message
// stack. We need this to be able to flip the scrollbar on/off
const pushing = ref(false);

const { t } = useI18n();

const props = defineProps<{ language: string; tenant: string }>();
const emit = defineEmits(["close"]);

const questionStep = ref(0);
const showAnswers = ref(true);
const actions: Ref<InstanceType<typeof OnboardingActions> | null> = ref(null);

const dialog: Ref<IDialog> = ref({ messages: [], topic: "" } as any as IDialog);

const questions = computed(() => {
  return [
    {
      text: t("tenantMessages.widgetBoxAcceptanceQuestion"),
      action: acceptanceAction,
      options: [
        {
          text: t("counseling.start.acceptance.yes"),
          actionPayload: true,
        },
        {
          text: t("counseling.start.acceptance.no"),
          actionPayload: false,
        },
      ],
    },
    {
      text: t("tenantMessages.widgetBoxReturningQuestion"),
      action: returningAction,
      options: [
        {
          text: t("counseling.start.returning.no"),
          actionPayload: false,
        },
        {
          text: t("counseling.start.returning.yes"),
          actionPayload: true,
        },
      ],
    },
    {
      text: t("tenantMessages.widgetBoxUsername"),
      action: usernameAction,
      options: [
        {
          text: t("tenantMessages.widgetBoxUsername"),
          type: "input",
        },
      ],
    },
    {
      text: t("tenantMessages.widgetBoxCohort"),
      action: cohortAction,
      options: [
        {
          text: t("tenantMessages.widgetBoxCohort"),
          type: "input",
          inputType: "number",
          inputValidator: (value: string) => {
            if (!/^\d{4}$/.test(value)) {
              return false;
            }

            const intValue = parseInt(value);

            const today = new Date();
            return intValue >= 1903 && intValue <= today.getFullYear();
          },
        },
      ],
    },
    {
      text: t("tenantMessages.widgetBoxGender"),
      action: genderAction,
      options: [
        {
          text: t("gender.male"),
          actionPayload: Genders.male,
        },
        {
          text: t("gender.female"),
          actionPayload: Genders.female,
        },
        {
          text: t("gender.nonbinary"),
          actionPayload: Genders.nonbinary,
        },
        {
          text: t("gender.unspecified"),
          actionPayload: Genders.unspecified,
        },
      ],
    },
  ];
});

/**
 * Overflow class. If we're currently pushing, flip it off
 */
const overflowClass = computed(() => {
  if (pushing.value) {
    return "sz-overflow-hidden";
  }
  return "sz-overflow-y-auto";
});

const possibleAnswers = computed(() => {
  return questions.value[questionStep.value].options;
});

onMounted(() => {
  pushNextQuestion(0);
  showAnswers.value = false;
  setTimeout(() => {
    showAnswers.value = true;
  }, QUESTION_DELAY * 2);
});

/**
 * Pose next question in the list to the client
 */
const pushNextQuestion = (delay: number = QUESTION_DELAY) => {
  pushing.value = true;
  setTimeout(() => {
    pushBotMessage(questions.value[questionStep.value].text.toString());
    setTimeout(() => {
      pushing.value = false;
    }, 500);
  }, delay);
};

/**
 * Push a message to the conversation as bot
 */
const pushBotMessage = (text: string) => {
  dialog.value.messages.push({
    id: dialog.value.messages.length,
    text,
    isCounselor: true,
    timestamp: new Date(),
  });
};

/**
 * Push a message to the conversation as client
 */
const pushClientMessage = (text: string) => {
  dialog.value.messages.push({
    id: dialog.value.messages.length,
    text,
    isCounselor: false,
    timestamp: new Date(),
  });
};

/**
 * Scroll the actions into the view
 */
const scrollIntoView = () => {
  if (!actions.value) return;

  actions.value.$el.scrollIntoView();
};

/**
 * Advance to the next question, receive the clicked or entered answer
 */
const updateQuestionStep = (payload: any) => {
  showAnswers.value = false;
  pushing.value = true;
  const action: Function = questions.value[questionStep.value].action;
  const cont = action.apply(this, [payload]);

  if (cont) {
    setTimeout(() => {
      pushing.value = false;
      showAnswers.value = true;
      nextTick(scrollIntoView);
    }, QUESTION_DELAY * 2);
  }
};

/**
 * Ask the client whether they want a counselling
 *
 * If yes: advance to the next question
 * If no: close the dialog
 */
const acceptanceAction = (payload: any) => {
  const idx = payload ? 0 : 1;

  pushClientMessage(questions.value[0].options[idx].text.toString());

  if (payload) {
    questionStep.value++;
    pushNextQuestion();

    return true;
  } else {
    const pushMessage = () => {
      pushBotMessage(t("counseling.start.acceptance.noAnswer").toString());
    };

    const close = () => {
      emit("close");
    };

    setTimeout(() => {
      pushMessage();

      setTimeout(() => {
        close();
      }, 1500);
    }, QUESTION_DELAY);

    return false;
  }
};

/**
 * Ask the client, whether they already have been counselled before
 *
 * If no: advance to next question
 * If yes: redirect to login page
 */
const returningAction = (payload: any) => {
  const idx = payload ? 1 : 0;

  pushClientMessage(
    questions.value[questionStep.value].options[idx].text.toString()
  );

  if (!payload) {
    questionStep.value++;
    pushNextQuestion();

    return true;
  } else {
    const pushMessage = () => {
      pushBotMessage(t("counseling.start.returning.noAnswer").toString());
    };

    const redirectToLogin = () => {
      const lang = props.language;
      const tenant = props.tenant;

      window.location.href = `${runtimeEnv.VITE_CLIENT_URL}/${lang}/login?tenant=${tenant}`;
    };

    setTimeout(() => {
      pushMessage();
      setTimeout(redirectToLogin, 1500);
    }, QUESTION_DELAY);
  }
};

/**
 * Ask the client for their username
 */
const usernameAction = (payload: any) => {
  pushClientMessage(payload);
  onboardingStore.setUsername(payload);
  questionStep.value++;
  pushNextQuestion();
  return true;
};

/**
 * Ask the client for their cohort
 */
const cohortAction = (payload: any) => {
  pushClientMessage(payload);
  onboardingStore.setCohort(payload);
  questionStep.value++;
  pushNextQuestion();
  return true;
};

/**
 * Ask the client for their gender
 */
const genderAction = (payload: any) => {
  const text = t(`gender.${payload}`).toString();
  onboardingStore.setGender(payload);

  pushClientMessage(text);
  pushBotMessage(t("tenantMessages.widgetBoxThanks").toString());
  finishAndRedirectOnboarding();

  return false;
};

/**
 * Redirect to the conversation page with the data urlencoded
 */
const finishAndRedirectOnboarding = () => {
  const clientUrl = runtimeEnv.VITE_CLIENT_URL;

  setTimeout(() => {
    let urlParameterData = {
      name: encodeURIComponent(onboardingStore.username || ""),
      gender: encodeURIComponent(onboardingStore.gender || "unspecified"),
      cohort: encodeURIComponent((onboardingStore.cohort || "").toString()),
      tenant: props.tenant,
      // preferred: btoa(onboardingStore.preferredCounselorGender || ''),
    };

    const urlParameters = Object.entries(urlParameterData)
      .map((pair) => pair.join("="))
      .join("&");

    const dialogUrl = `${clientUrl}/${props.language}/counseling/?${urlParameters}`;
    let newWin = window.open(dialogUrl, "_blank");

    if (!newWin || newWin.closed || typeof newWin.closed == "undefined") {
      dialog.value.messages.push({
        id: dialog.value.messages.length,
        text:
          t("tenantMessages.widgetBoxPermission").toString() + " " + dialogUrl,
        isCounselor: true,
        timestamp: new Date(),
      });
    }
  }, 750);
};
</script>

<style lang="postcss" scoped>
.popup {
  box-shadow: 0 0 10px rgba(90, 77, 77, 0.5);
  max-height: 515px;
  min-width: 300px;
}

:deep() a {
  @apply sz-inline-block sz-underline sz-break-all;
}
</style>
